import { ReactNode } from "react";
import type { TFunction } from "react-i18next";
import { EAPP_ROUTE } from "../../../enums/route.enum";
import { Dashboard as DashboardIcon } from "../../../icons/dashboard";
import { ReceiptTax } from "../../../icons/receipt-tax";
import { Truck } from "../../../icons/truck";
import { Eco } from "../../../icons/eco";

interface Item {
  id?: any;
  title: string;
  children?: Item[];
  chip?: ReactNode;
  icon?: ReactNode;
  path?: string;
  sidebarVisible?: boolean;
}

interface Section {
  title: string;
  items: Item[];
}

export const getSections = (t: TFunction): Section[] => [
  {
    title: t("menu__placeholders__general_placeholder"),
    items: [
      {
        title: t("menu__items__main_page_menu_option"),
        path: EAPP_ROUTE.MAIN,
        sidebarVisible: true,
        icon: <DashboardIcon fontSize="small" />,
      },
      {
        title: t("menu__items__articles_menu_option"),
        path: EAPP_ROUTE.ARTICLES_VIEW,
        sidebarVisible: true,
        icon: <Eco fontSize="small" />,
      },

      {
        title: t("menu__items__invoices_menu_option"),
        path: EAPP_ROUTE.INVOICES,
        sidebarVisible: true,
        icon: <ReceiptTax fontSize="small" />,
      },
    ],
  },
  {
    title: t("menu__placeholders__management_placeholder"),
    items: [
      {
        title: t("menu__items__suppliers_menu_option"),
        path: EAPP_ROUTE.SUPPLIERS,
        sidebarVisible: true,
        icon: <Truck fontSize="small" />,
      },
      {
        title: t("menu__items__search_menu_option"),
        path: EAPP_ROUTE.CATALOG,
        sidebarVisible: true,
        icon: <Eco fontSize="small" />,
      },
    ],
  },
];
/*
title: t("general"),
    items: [
      {
        title: t("main-page__title"),
        path: EAPP_ROUTE.MAIN,
        sidebarVisible: true,
        icon: <DashboardIcon fontSize="small" />,
      },
      {
        title: t("price-monitoring__sidebar-title"),
        path: EAPP_ROUTE.PRICE_MONITORING,
        sidebarVisible: true,
        icon: <QueryStatsIcon fontSize="small" />,
        children: [
          {
            title: t("sourcing__articles__title"),
            path: EAPP_ROUTE.PRICE_MONITORING_ARTICLES,
            sidebarVisible: true,
            icon: <Eco fontSize="small" />,
          },
        ],
      },
      {
        title: t("sourcing__title"),
        path: EAPP_ROUTE.SOURCING,
        sidebarVisible: true,
        icon: <FoodBankIcon fontSize="small" />,
        children: [
          {
            title: t("sourcing_articles_page__title"),
            path: EAPP_ROUTE.SOURCING_ARTICLES,
            sidebarVisible: true,
            icon: <Eco fontSize="small" />,
          },
          {
            title: t("suppliers_page__suppliers"),
            path: EAPP_ROUTE.SUPPLIERS,
            sidebarVisible: true,
            icon: <Truck fontSize="small" />,
          },
        ],
      },
      {
        title: t("invoices_page__invoices"),
        path: EAPP_ROUTE.INVOICES,
        sidebarVisible: true,
        icon: <ReceiptTax fontSize="small" />,
      },
    ],*/
