import { useRouter } from "next/router";
import { removeNewOutletsAction } from "../redux/action/new-outlets";
import { removeOutletsAction } from "../redux/action/outlets";
import { removeParentOrganizationAction } from "../redux/action/parent-organization";
import { removeSelectedOutletAction } from "../redux/action/selected-outlet";
import { removeSelectedUnitAction } from "../redux/action/selected-unit";
import { removeSetupUserAction } from "../redux/action/setup-user";
import { removeSuppliersAction } from "../redux/action/suppliers";
import { removeUserAction } from "../redux/action/user";
import { Store } from "../redux/store";
import { removeToken } from "../service/service-utils/service-utils";

export const useLogout = () => {
  const router = useRouter();

  return () => {
    clearRedux();
    router.push("/api/new-logout");
  };
};

export const clearRedux = () => {
  removeToken();
  Store.dispatch(removeUserAction());
  Store.dispatch(removeOutletsAction());
  Store.dispatch(removeSelectedOutletAction());
  Store.dispatch(removeSelectedUnitAction());
  Store.dispatch(removeSuppliersAction());
  Store.dispatch(removeParentOrganizationAction());
  Store.dispatch(removeNewOutletsAction());
  Store.dispatch(removeSetupUserAction());
};
