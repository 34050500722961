import type { FC } from "react";
import { useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
} from "@mui/material";
import { Scrollbar } from "../../share/scrollbar";
import { useTranslation } from "react-i18next";
import router from "next/router";
import { useSelector } from "react-redux";
import { IReduxState } from "../../../redux/app_store";
import { apiRequestHandler } from "../../../api/requestHandler";

interface InvitesPopoverProps {
  anchorEl: null | Element;
  onClose: () => void;
  onUpdateUnread?: (value: number) => void;
  open?: boolean;
  invites?: any;
}

export const InvitesPopover: FC<InvitesPopoverProps> = (props) => {
  const { anchorEl, onClose, onUpdateUnread, open, ...other } = props;
  const { t } = useTranslation();
  const { selectedUnit, user } = useSelector((store: IReduxState) => store);

  const [invites, _] = useState<any[]>([]);
  const unread = useMemo(
    () => invites.reduce((acc, invite) => acc + (invite.read ? 0 : 1), 0),
    [invites]
  );

  useEffect(() => {
    onUpdateUnread?.(unread);
  }, [onUpdateUnread, unread]);

  const handlerInvitation = async (id, operation) => {
    await apiRequestHandler(
      `/api/hospitad/user-management/user/invitations/${id}/${operation}`,
      "POST",
      {},
      {
        "x-user-id": user?.id,
        "x-unit-id": selectedUnit?.id,
        "Content-Type": "application/json",
      }
    );
    router.reload();
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "left",
        vertical: "bottom",
      }}
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 380 } }}
      transitionDuration={0}
      {...other}
    >
      <Box
        sx={{
          alignItems: "center",
          backgroundColor: "primary.main",
          color: "primary.contrastText",
          display: "flex",
          justifyContent: "space-between",
          px: 3,
          py: 2,
        }}
      >
        <Typography color="inherit" variant="h6">
          {t("account_page__outlets_invite__title")}
        </Typography>
        {/* <Tooltip title="Mark all as read">
          <IconButton
            onClick={handleMarkAllAsRead}
            size="small"
            sx={{ color: 'inherit' }}
          >
            <MailOpenIcon fontSize="small" />
          </IconButton>
        </Tooltip> */}
      </Box>
      {props?.invites?.length === 0 ? (
        <Box sx={{ p: 2 }}>
          <Typography variant="subtitle2">
            {t("account_page__outlets_invite__no_invites")}
          </Typography>
        </Box>
      ) : (
        <Scrollbar sx={{ maxHeight: 400 }}>
          <List disablePadding>
            {props?.invites?.map((invite) => {
              return (
                <ListItem
                  divider
                  key={invite.id}
                  sx={{
                    cursor: "pointer",
                    alignItems: "flex-start",
                    "&:hover": {
                      backgroundColor: "action.hover",
                    },
                    "& .MuiListItemSecondaryAction-root": {
                      top: "24%",
                    },
                  }}
                >
                  <>
                    <ListItemText
                      primary={
                        <Box
                          sx={{
                            alignItems: "center",
                            display: "flex",
                            flexWrap: "wrap",
                            m: 0,
                          }}
                        >
                          <Typography variant="body2" sx={{ mr: 0.5 }}>
                            {`${t(
                              "account_page__outlets_invite__message__before_invite_name"
                            )} ${invite.role} ${t(
                              "account_page__outlets_invite__message__between_invite_name"
                            )} ${invite.parentUnitId} ${t(
                              "account_page__outlets_invite__message__after_invite_name"
                            )} ${invite.inviterUserId} `}
                          </Typography>
                          <Box sx={{ display: "flex", my: 1 }}>
                            <Button
                              color="primary"
                              variant="outlined"
                              onClick={() => {
                                handlerInvitation(invite.id, "decline");
                              }}
                            >
                              {t(
                                "account_page__outlets_invite__buttons__decline"
                              )}
                            </Button>

                            <Button
                              type="submit"
                              variant="contained"
                              sx={{ mx: 2 }}
                              onClick={() => {
                                handlerInvitation(invite.id, "accept");
                              }}
                            >
                              {t(
                                "account_page__outlets_invite__buttons__accept"
                              )}
                            </Button>
                          </Box>
                        </Box>
                      }
                      secondary={
                        <Typography color="textSecondary" variant="caption">
                          {format(new Date(invite?.date), "MMM dd, h:mm a")}
                        </Typography>
                      }
                      sx={{ my: 0 }}
                    />
                  </>
                </ListItem>
              );
            })}
          </List>
        </Scrollbar>
      )}
    </Popover>
  );
};
