import type { FC } from "react";
import {
  Box,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { IReduxState } from "../../../redux/app_store";
import { IOutlet } from "../../../model/outlet.model";
import { setSelectedUnitAction } from "../../../redux/action/selected-unit";
import { setSelectedOutletAction } from "../../../redux/action/selected-outlet";
import { IUnit } from "../../../model/unit.model";
import { AppDB } from "../../../persistence";
import { useRouter } from "next/router";
import { Scrollbar } from "../../share/scrollbar";
import { Divider } from "@material-ui/core";
import NextLink from "next/link";
import { Cog as CogIcon } from "../../../icons/cog";
import { EAPP_ROUTE } from "../../../enums/route.enum";
import { useTranslation } from "react-i18next";
interface IProps {
  anchorEl: null | Element;
  onClose?: () => void;
  open?: boolean;
}

export const OutletPopover: FC<IProps> = (props) => {
  const { anchorEl, onClose, open, ...other } = props;
  const { outlets, user, selectedOutlet } = useSelector(
    (store: IReduxState) => store
  );
  const dispatch = useDispatch();
  const router = useRouter();
  const query = router.query;
  const { t } = useTranslation();

  const handleChange = (outlet: IOutlet) => {
    onClose?.();
    const unit: IUnit = {
      id: outlet.mainUnitId!,
      name: "",
      role: outlet.role!,
    };
    dispatch(setSelectedUnitAction(unit!));
    dispatch(setSelectedOutletAction(outlet));

    AppDB.selectedOutlet.put({ id: user?.id!, outlet });

    if (query && query.outletId) {
      let newQuery = { ...query, outletId: outlet.id };
      const newParams = new URLSearchParams(newQuery).toString();
      router.push(`${router.route}?${newParams}`);
    }
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "left",
        vertical: "bottom",
      }}
      keepMounted
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 248 } }}
      transitionDuration={0}
      {...other}
    >
      <Box>
        <Scrollbar sx={{ maxHeight: "18.5rem" }}>
          {outlets
            .sort((a, b) =>
              a.displayName
                .toLowerCase()
                .localeCompare(b.displayName.toLowerCase())
            )
            .reduce((acc: any, outlet) => {
              if (selectedOutlet?.displayName !== outlet.displayName) {
                acc.push(
                  <MenuItem
                    key={outlet.id}
                    onClick={() => handleChange(outlet)}
                    data-testid={outlet.displayName}
                  >
                    {outlet.displayName}
                  </MenuItem>
                );
              }

              return acc;
            }, [])}
        </Scrollbar>
      </Box>
      <Divider />
      <NextLink href={EAPP_ROUTE.SETTING_OUTLET} passHref>
        <MenuItem component="a" onClick={onClose}>
          <ListItemIcon>
            <CogIcon fontSize="large" />{" "}
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">{t("outlet_settings")}</Typography>
            }
          />
        </MenuItem>
      </NextLink>
    </Popover>
  );
};
