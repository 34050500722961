import { useRef, useState } from "react";
import { Avatar, Box, ButtonBase } from "@mui/material";
import { AccountPopover } from "./account-popover";
import { useSelector } from "react-redux";
import { IReduxState } from "../../../redux/app_store";

export const AccountButton = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [openPopover, setOpenPopover] = useState<boolean>(false);
  const { user } = useSelector((store: IReduxState) => store);
  const handleOpenPopover = (): void => {
    setOpenPopover(true);
  };

  const handleClosePopover = (): void => {
    setOpenPopover(false);
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpenPopover}
        ref={anchorRef}
        sx={{
          alignItems: "center",
          display: "flex",
          ml: 2,
        }}
        data-testid="user-avatar"
        id="settings-button"
      >
        <Avatar
          sx={{
            height: 40,
            width: 40,
          }}
          src={user?.picture}
        ></Avatar>
      </Box>
      <AccountPopover
        anchorEl={anchorRef.current}
        onClose={handleClosePopover}
        open={openPopover}
      />
    </>
  );
};
