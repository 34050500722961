import { FC, useState } from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  FormControlLabel,
  IconButton,
  Switch,
  Toolbar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import type { AppBarProps } from "@mui/material";
import { AccountButton } from "./navbar-account-button";
import { Menu as MenuIcon } from "../../../icons/menu";
import config from "../../../utils/config";
//import { NotificationsButton } from "./notification-button";
import { useTranslation } from "react-i18next";
import { InvitesButton } from "./invite-button";

interface DashboardNavbarProps extends AppBarProps {
  onOpenSidebar?: () => void;
  width: string;
  isOnboarding?: boolean;
  isSettingOutletPage?: boolean;
}

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  ...(theme.palette.mode === "light"
    ? {
        boxShadow: theme.shadows[3],
      }
    : {
        backgroundColor: theme.palette.background.paper,
        borderBottomColor: theme.palette.divider,
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        boxShadow: "none",
      }),
}));

const HideFlagSwitcher = () => {
  const { t } = useTranslation();
  const [hideFlag, setHideFlag] = useState(
    localStorage.getItem("hideFlag") === "true" ? false : true
  );

  const handleChange = () => {
    setHideFlag(!hideFlag);
    localStorage.setItem("hideFlag", hideFlag ? "true" : "false");
    window.dispatchEvent(new Event("storage"));
  };

  return (
    <Box sx={{ m: 0 }}>
      <FormControlLabel
        control={<Switch name="hideFlag" checked={hideFlag} />}
        label={t("application_utils__development_flag_label")}
        onChange={() => handleChange()}
        sx={{ color: "text.secondary" }}
      />
    </Box>
  );
};
export const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
  const NEXT_PUBLIC_appEnv = config.appEnv;

  const { isSettingOutletPage, onOpenSidebar, width, ...other } = props;

  return (
    <>
      <DashboardNavbarRoot
        sx={{
          left: {
            lg: width,
          },
          width: {
            lg: `calc(100% - ${width})`,
          },
        }}
        {...other}
      >
        <Toolbar
          disableGutters
          sx={{
            minHeight: 64,
            left: 0,
            px: 2,
          }}
        >
          {!props.isOnboarding && (
            <IconButton
              onClick={onOpenSidebar}
              data-testid="menu-icon"
              id="menu-button"
              sx={{
                display: "inline-flex",
              }}
            >
              <MenuIcon fontSize="large" />
            </IconButton>
          )}

          <Box sx={{ flexGrow: 1 }} />
          {NEXT_PUBLIC_appEnv === "staging" ? <HideFlagSwitcher /> : null}
          {/* <NotificationsButton /> */}
          <InvitesButton />
          <AccountButton />
        </Toolbar>
      </DashboardNavbarRoot>
    </>
  );
};

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};
