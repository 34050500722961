import {
  Badge,
  IconButton,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  useTheme,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";

import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IReduxState } from "../../../redux/app_store";
import { useQuery } from "react-query";
import { apiRequestHandler } from "../../../api/requestHandler";
import { InvitesPopover } from "./invite-popover";
const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: "100%",
    fontSize: "1rem",
    border: "1px solid #dadde9",
  },
}));
export const InvitesButton: any = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [unread, setUnread] = useState<number>(0);
  const [openPopover, setOpenPopover] = useState<boolean>(false);
  const { t } = useTranslation();
  // Unread notifications should come from a context and be shared with both this component and
  // notifications popover. To simplify the demo, we get it from the popover
  const theme = useTheme();
  const { user } = useSelector((store: IReduxState) => store);
  const handleOpenPopover = (): void => {
    setOpenPopover(!openPopover);
  };

  const handleClosePopover = (): void => {
    setOpenPopover(!openPopover);
  };

  const handleUpdateUnread = (value: number): void => {
    setUnread(value);
  };

  const getNotifications: any = useQuery<any>(
    ["USER-INVITES", user],
    async () => {
      const data: any = await apiRequestHandler(
        `/api/hospitad/user-management/user/invitations?states=REQUESTED`,
        "GET",
        {},
        {
          "x-user-id": user?.id,
          "Content-Type": "application/json",
        }
      );
      return data;
    }
  );
  useEffect(() => {
    setUnread(getNotifications?.data?.length || 0);
  }, [getNotifications]);

  return (
    !!unread && (
      <>
        <HtmlTooltip title={`${t("account_page__notifications__tab_title")}`}>
          <IconButton
            ref={anchorRef}
            sx={{ ml: 1 }}
            onClick={handleOpenPopover}
          >
            <Badge
              color="error"
              showZero={true}
              badgeContent={unread}
              sx={{ span: { fontSize: "1rem", fontWeight: "bold" } }}
            >
              <AddBusinessIcon
                style={{ color: theme.palette.action.active, fontSize: "22px" }}
              />
            </Badge>
          </IconButton>
        </HtmlTooltip>
        <InvitesPopover
          anchorEl={anchorRef.current}
          onClose={handleClosePopover}
          onUpdateUnread={handleUpdateUnread}
          open={openPopover}
          invites={getNotifications?.data}
        />
      </>
    )
  );
};
