import type { FC, ReactNode } from "react";
import PropTypes from "prop-types";
import { List, ListSubheader } from "@mui/material";
import type { ListProps } from "@mui/material";
import { DashboardSidebarItem } from "./dashboard-sidebar-item";

interface Item {
  path?: string;
  icon?: ReactNode;
  chip?: ReactNode;
  info?: ReactNode;
  children?: Item[];
  title: string;
  sidebarVisible?: boolean;
}

interface DashboardSidebarSectionProps extends ListProps {
  items: Item[];
  path: string;
  closeDrawerTrigger: Function;
  title: string;
}

const renderNavItems = ({
  depth = 0,
  items,
  path,
  closeDrawerTrigger,
}: {
  depth?: number;
  items: Item[];
  path: string;
  closeDrawerTrigger: Function;
}): JSX.Element => (
  <List disablePadding>
    {items.reduce(
      (acc: JSX.Element[], item) =>
        reduceChildRoutes({ acc, depth, item, path, closeDrawerTrigger }),
      []
    )}
  </List>
);

const reduceChildRoutes = ({
  acc,
  depth,
  item,
  path,
  closeDrawerTrigger,
}: {
  acc: JSX.Element[];
  depth: number;
  item: Item;
  path: string;
  closeDrawerTrigger: Function;
}): Array<JSX.Element> => {
  const key = `${item.title}-${depth}`;
  const partialMatch = item.path ? path.includes(item.path) : false;
  const exactMatch = path.split("?")[0] === item.path; // We don't compare query params
  if (item.sidebarVisible) {
    if (item.children) {
      acc.push(
        <DashboardSidebarItem
          active={partialMatch}
          chip={item.chip}
          depth={depth}
          icon={item.icon}
          info={item.info}
          key={key}
          open={partialMatch}
          path={item.path}
          title={item.title}
          closeDrawerTrigger={closeDrawerTrigger}
        >
          {renderNavItems({
            depth: depth + 1,
            items: item.children,
            path,
            closeDrawerTrigger,
          })}
        </DashboardSidebarItem>
      );
    } else {
      acc.push(
        <DashboardSidebarItem
          active={exactMatch}
          chip={item.chip}
          depth={depth}
          icon={item.icon}
          info={item.info}
          key={key}
          path={item.path}
          title={item.title}
          closeDrawerTrigger={closeDrawerTrigger}
        />
      );
    }
  }

  return acc;
};

export const DashboardSidebarSection: FC<DashboardSidebarSectionProps> = (
  props
) => {
  const { id, items, path, title, closeDrawerTrigger, ...other } = props;

  return (
    <List
      id={id}
      sx={{ mb: 4 }}
      subheader={
        <ListSubheader
          disableGutters
          disableSticky
          sx={{
            color: "neutral.500",
            fontSize: "1.2rem",
            fontWeight: 700,
            lineHeight: 2.5,
            ml: 4,
            textTransform: "uppercase",
          }}
          data-testid={title}
          id={id}
        >
          {title}
        </ListSubheader>
      }
      {...other}
    >
      {renderNavItems({
        items,
        path,
        closeDrawerTrigger,
      })}
    </List>
  );
};

DashboardSidebarSection.propTypes = {
  items: PropTypes.array.isRequired,
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
